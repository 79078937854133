<template>
  <div>
    <el-form
      :model="action"
      ref="formedForm"
      label-position="top"
      label-width="120px"
    >
      <el-row :gutter="10">
        <el-col :span="8">
          <el-form-item label="Количество" prop="store">
            <el-input v-model="action.inputAmount"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="Дата" prop="date">
            <el-date-picker
              value-format="dd.MM.yyyy"
              v-model="action.date"
              type="date"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-form-item label="Сопроводительный паспорт"  v-if="fasle">
          <el-select
            :disabled=true
            clearable
            filterable
            class="searchPole"
            v-model="action.passportNumberId"
            value-key="id"
            placeholder="ReceiptReasonsCode"
          >
            <el-option
              v-for="item in passports"
              :key="item.id"
              :label="item.number"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="Причина поступления"  v-if="action.pss17ReceiptReasonsCode != 0">
          <el-select
            clearable
            filterable
            class="searchPole"
            v-model="action.pss17ReceiptReasonsCode"
            value-key="id"
            placeholder="ReceiptReasonsCode"
          >
            <el-option
              v-for="item in pss17"
              :key="item.id"
              :label="item.reason"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Причина передачи"  v-if="action.pss18TransferReasonsCode != 0 && action.actionInfo.id==10">
          <el-select
            clearable
            filterable
            class="searchPole"
            v-model="action.pss18TransferReasonsCode"
            value-key="id"
            placeholder="TransferReasonsCode"
          >
            <el-option
              v-for="item in pss18"
              :key="item.id"
              :label="item.reason"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Причина передачи"  v-if="action.pss18TransferReasonsCode != 0 && action.actionInfo.id!=10">
          <el-select
            clearable
            filterable
            class="searchPole"
            v-model="action.pss18TransferReasonsCode"
            value-key="id"
            placeholder="TransferReasonsCode"
          >
            <el-option
              v-for="item in pss182"
              :key="item.id"
              :label="item.reason"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="Направление использования"  v-if="action.pss19DirectionsOfUseCode != 0">
          <el-select
            clearable
            filterable
            class="searchPole"
            v-model="action.pss19DirectionsOfUseCode"
            value-key="id"
            placeholder="DirectionsOfUseCode"
          >
            <el-option
              v-for="item in pss19"
              :key="item.id"
              :label="item.reason"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Способ обезвреживания"  v-if="action.pss20NeutralizeMethodsCode != 0">
          <el-select
            clearable
            filterable
            class="searchPole"
            v-model="action.pss20NeutralizeMethodsCode"
            value-key="id"
            placeholder="DirectionsOfUseCode"
          >
            <el-option
              v-for="item in pss20"
              :key="item.id"
              :label="item.reason"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Объекты захоронения отходов"  v-if="(action.pss18TransferReasonsCode == 6 || action.pss21BurialSidesCode != 0) || action.actionInfo.id == 6">
          <el-select
            clearable
            filterable
            class="searchPole"
            v-model="action.pss21BurialSidesCode"
            value-key="id"
            placeholder="Объекты захоронения отходов"
          >
            <el-option
              v-for="item in pss21"
              :key="item.id"
              :label="item.reason"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Наименование объекта, место хранение отходов" v-if="action.pss22StoreSidesCode != 0">
          <el-select
            clearable
            filterable
            class="searchPole"
            v-model="action.pss22StoreSidesCode"
            value-key="id"
            placeholder="DirectionsOfUseCode"
          >
            <el-option
              v-for="item in pss22"
              :key="item.id"
              :label="item.reason"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-row>
      <el-row :gutter="10" v-if="action.actionInfo.id==10 && action.contractor_name==null">
        <el-col :span="8">
          <el-form-item label="ФИО:">
            <el-input v-model="action.fio"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="action.passportNumberId!=0">
        <el-col :span="8">
          <el-form-item label="Тара (упаковка) - наименование:">
            <el-input v-model="action.taraName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="action.passportNumberId!=0">
        <el-col :span="8">
          <el-form-item label="Тара (упаковка) - общий вес, тонн:">
            <el-input v-model="action.taraVight"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-button type="primary" @click="save" >Сохранить</el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { AXIOS } from "@/AXIOS/http-common";
export default {
  props: ["action"],
  name: "formedModalUpdate",
  data() {
    return {
      passports:null,
      pss17:[{id:1,reason:"Для использования"},
      {id:2,reason:"Для обезвреживания"},
      {id:3,reason:"Для захоронения"},
      {id:4,reason:"Для хранения"},
      {id:5,reason:"Прочее"},],
      pss18:[{id:1,reason:"С целью использования"},
      {id:2,reason:"С целью обезвреживания"},
      {id:3,reason:"С целью захоронения по договору отчуждения"},
      {id:4,reason:"С целью хранения"},
      {id:5,reason:"Прочее"},
      {id:6,reason:"С целью захоронения без договора отчуждения"},
      {id:7,reason:"С целью заготовки"},
      {id:8,reason:"С целью сортировки"},],
      pss182:[{id:1,reason:"С целью использования"},
      {id:2,reason:"С целью обезвреживания"},
      {id:3,reason:"С целью захоронения"},
      {id:4,reason:"С целью хранения"},
      {id:5,reason:"Прочее"},],
      pss19:[{id:1,reason:"Для получения энергии"},
      {id:2,reason:"Для получения продукции"},
      {id:3,reason:"Для выполнения работ, оказания услуг"},
      {id:4,reason:"Для получения RDF-топлива"},
      {id:5,reason:"В качестве изолирующего материала на объектах захоронения твердых коммунальных отходов"},
      {id:6,reason:"Для рекультивации нарушенных земель"},],
      pss20:[{id:110,reason:"Термический способ"},
      {id:120,reason:"Физико-химическая обработка"},
      {id:130,reason:"Электрохимический способ"},
      {id:140,reason:"Иммобилизация"},
      {id:150,reason:"Химический способ"},
      {id:160,reason:"Восстановительный способ"},
      {id:170,reason:"Мембранный способ"},
      {id:180,reason:"Биологическая обработка"},
      {id:190,reason:"Другое"},],
      pss21:[{id:211,reason:"Полигон промышленных отходов"},
      {id:212,reason:"Полигон токсичных промышленных отходов"},
      {id:221,reason:"Полигон твердых коммунальных отходов "},
      {id:290,reason:"Другое"},],
      pss22:[{id:10,reason:"Шламохранилище (шламонакопитель)"},
      {id:20,reason:"Полигон токсичных промышленных отходов"},
      {id:30,reason:"Отвал"},
      {id:40,reason:"Объект хранения ила активного очистных сооружений"},
      {id:50,reason:"Подземный резервуар"},
      {id:60,reason:"Места временного хранения"},
      {id:70,reason:"Другое"},],
    }
  },
  methods: {
    async  getNumbersPassport(){
            return await AXIOS.get("report/pasportNumb/").then(
        (response) => {
          this.passports=response.data;
        }
        );
    },
    save() {
      if(this.action.pss18TransferReasonsCode !=6 && this.action.actionInfo.id!=6)
      this.action.pss21BurialSidesCode = 0;
      this.action.taraName = this.action.taraName == undefined ? null : this.action.taraName;
      this.action.taraVight = this.action.taraVight == undefined ? null : this.action.taraVight;
        this.$store.dispatch("updateWasteHistory", this.action).then(() => {
            console.log(1);
            this.notification("Успешно", "Движение обновлено", "success");
            console.log(2);
            this.$emit('getListAllHistorychildren');
            console.log(3);
            this.$store.dispatch("setShowModal", null);
        });
    },
  },

  mounted() {
    this.getNumbersPassport();
    console.log(this);
  },
};
</script>

<style scoped></style>
